import {
  Box,
  Checkbox,
  Divider,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useEffect, useRef, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/styles";
import FormControlLabel from "@mui/material/FormControlLabel";
import base from "../../api/apis";
import { LoadingButton } from "@mui/lab";
import { useDispatch, useSelector } from "react-redux";
import cardPlaceholder from "../../assets/propertyFavicon/savedHome.png";
// Custom dark btn
const CancelButton = styled(Button)(() => ({
  borderColor: "gray",
  color: "black",
  "&:hover": {
    borderColor: "gray",
  },
}));

export const SavedCardsModal = ({
  openFavoriteModal,
  setOpenFavoriteModal,
  categories,
  setCategories,
  primaryFavorite,
}) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { favorite } = state || {};
  const [loading, setLoading] = useState(false);
  const [createFavoritePopUp, setCreateFavoritePopUp] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState([]);

  useEffect(() => {
    if (!primaryFavorite?.id) return;
    setSelectedCategory((p) => [...p, primaryFavorite?.id]);
  }, [primaryFavorite]);

  const handleClose = () => {
    setOpenFavoriteModal(false);
  };

  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const toggleItem = (item) => {
    setSelectedCategory((prevItems) => {
      if (prevItems?.includes(item)) {
        // Remove item if it's already in the array
        return prevItems.filter((i) => i !== item);
      } else {
        // Add item if it's not in the array
        return [...prevItems, item];
      }
    });
  };

  const saveCategoriesAddedOrRemoved = async () => {
    setLoading(true);

    try {
      await base.post("favorite/properties/", {
        ...favorite,
        category: selectedCategory,
      });
      setOpenFavoriteModal(false);
      dispatch({
        type: "SUCCESS",
        payload: "List Updated",
      });
      setLoading(false);
    } catch (error) {
      dispatch({
        type: "ERROR",
        payload: "Something went wrong",
      });
    }
    setLoading(false);
  };

  return (
    <div>
      <CreateNewFavoritePopUp
        setCategories={setCategories}
        setSelectedCategory={setSelectedCategory}
        createFavoritePopUp={createFavoritePopUp}
        setCreateFavoritePopUp={setCreateFavoritePopUp}
      />
      <Dialog
        open={openFavoriteModal}
        fullWidth={true}
        onClose={handleClose}
        maxWidth={"sm"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Add to List</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Button
              startIcon={
                <AddIcon
                  style={{
                    border: "solid blue 1px",
                    padding: " .8rem 1.1rem",

                    borderRadius: "5px",
                  }}
                />
              }
              fullWidth
              color="secondary"
              sx={{
                justifyContent: "flex-start",
                textTransform: "none",
                textAlign: "start",
              }}
              onClick={() => {
                setCreateFavoritePopUp(true);
              }}
            >
              Create a new category
            </Button>
            {categories?.map((cat) => (
              <div key={cat?.id}>
                <Divider sx={{ mb: ".5rem" }} />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box sx={{ display: "flex" }}>
                    <ImageWithPlaceholder
                      placeholder={cardPlaceholder}
                      src={cat?.favorite_images?.[0]}
                      alt="Just a random"
                    />
                    <div>
                      <Typography>{cat?.name}</Typography>
                      <Typography fontSize={12}>
                        {cat.is_primary && "Primary List"}
                      </Typography>
                    </div>
                  </Box>
                  <Checkbox
                    {...label}
                    // disabled={
                    //   selectedCategory.length >= 2 &&
                    //   !selectedCategory.includes(cat.id)
                    // }
                    checked={selectedCategory.includes(cat.id)}
                    onClick={() => {
                      toggleItem(cat.id);
                    }}
                  />
                </Box>
                <Divider sx={{ mt: ".5rem" }} />
              </div>
            ))}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <CancelButton
            fullWidth
            variant="outlined"
            sx={{ textTransform: "none" }}
            onClick={handleClose}
          >
            Cancel
          </CancelButton>
          <LoadingButton
            color="secondary"
            sx={{ textTransform: "none" }}
            disableElevation
            fullWidth
            loading={loading}
            variant="contained"
            onClick={saveCategoriesAddedOrRemoved}
            autoFocus
          >
            Save
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

// Creating a new Favorite home popup

export const CreateNewFavoritePopUp = ({
  createFavoritePopUp,
  setCreateFavoritePopUp,
  setCategories,
  setSelectedCategory,
}) => {
  const state = useSelector((state) => state);
  const { favorite } = state || {};
  const [listName, setListName] = useState("");
  const [makeFavorite, setMakeFavorite] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const handleClose = () => {
    setCreateFavoritePopUp(false);
  };

  const [newlyAddedCat, setNewlyAddedCat] = useState([]);

  useEffect(() => {
    if (!newlyAddedCat.length) return;

    setCategories((p) => [...p, newlyAddedCat]);
    /* eslint-disable */
  }, [newlyAddedCat]);

  // creating a category
  const submitForm = async () => {
    setLoading(true);
    try {
      const { data } = await base.post("favorite/category/", {
        name: listName,
        is_primary: makeFavorite,
      });
      dispatch({ type: "CATEGORY_CREATED", payload: data });

      setSelectedCategory((p) => [...p, data?.id]);
      // adding a just favorited property to the newly created category
      setNewlyAddedCat(data);
      await base.post("favorite/properties/", {
        ...favorite,
        category: [data?.id],
      });

      setLoading(false);
      dispatch({
        type: "SUCCESS",
        payload: "New List Created Successfully",
      });

      handleClose();
    } catch (error) {
      dispatch({
        type: "ERROR",
        payload: "Something went wrong",
      });
      setLoading(false);
    }
  };

  return (
    <div>
      {" "}
      <Dialog
        BackdropProps={{
          sx: {
            backgroundColor: "rgba(0, 0, 0, 0.1)",
          },
        }}
        open={createFavoritePopUp}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Create a new list</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={(theme) => ({
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Typography>List Name</Typography>
          <TextField
            onChange={(e) => setListName(e.target.value)}
            size="small"
          />
          <Box>
            <FormControlLabel
              control={
                <Checkbox onChange={(e) => setMakeFavorite(e.target.checked)} />
              }
              label={
                <Typography fontSize={13}>
                  Make this your primary list
                </Typography>
              }
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            loading={loading}
            color="secondary"
            sx={{ textTransform: "none" }}
            fullWidth
            variant="contained"
            onClick={submitForm}
            autoFocus
          >
            Save
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

// check for image validity
const ImageWithPlaceholder = ({ src, placeholder, alt }) => {
  const imgRef = useRef(null);
  const [thumbnailSrc, setThumbnailSrc] = useState(src);

  const defaultThumbnailSrc = placeholder;
  const handleImgValidity = () => {
    if (imgRef.current.naturalHeight <= 90 || null || undefined) {
      setThumbnailSrc(defaultThumbnailSrc);
    }
  };

  return (
    <img
      style={{
        height: "3rem",
        width: "4rem",
        borderRadius: 5,
        marginRight: 5,
      }}
      ref={imgRef}
      src={thumbnailSrc || defaultThumbnailSrc}
      onError={handleImgValidity}
      onLoad={handleImgValidity}
      alt={alt}
    />
  );
};
