import React from "react";
import Logo from "../assets/logo_2.png";
import {
  Box,
  Container,
  Divider,
  Grid,
  Hidden,
  Link,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { grey } from "@mui/material/colors";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import MLS from "../assets/mlsLogo.png";

const useStyles = makeStyles((theme) => ({
  wrap: {
    background: grey[100],
    marginTop: "6rem",
    padding: "5rem 0 3rem 0",
  },
  listItem: {
    paddingLeft: 0,
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
    "&:hover": {
      background: "transparent",
    },
  },
  footImg: {
    [theme.breakpoints.down("sm")]: {
      width: "60%",
    },
  },
}));
const Footer = () => {
  const classes = useStyles();
  const { vendor } = useParams();
  const path = useLocation().pathname;
  const navigate = useNavigate();

  const footerLinks = [
    {
      caption: "For Sellers",
      subs: [
        { name: "Find a Listing Agent", path: "/agents" },
        { name: "Open Houses", path: "/homes-for-sale" },
      ],
    },
    {
      caption: "For Buyers ",
      subs: [
        { name: "Find a Buyer's Agent", path: "/agents" },
        { name: "Find Home for Sale", path: "/homes-for-sale" },
      ],
    },
    {
      caption: "For Agents",
      subs: [{ name: "Join BPO Homes Realty", path: "/join" }],
    },
  ];

  const menu = [
    { name: "About", path: "/about" },
    { name: "Blog", path: "/blog" },
    { name: "Privacy Policy", path: "/privacy-policy" },
    { name: "Terms of Use", path: "/terms-of-use" },
    { name: "Disclaimers", path: "/disclaimers" },
  ];
  return (
    <>
      <Box className={classes.wrap}>
        <Container>
          <Box width={"85%"} margin={"0 auto"}>
            <Grid container spacing={3}>
              {footerLinks.map((el) => {
                return (
                  <Grid
                    key={el.caption}
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={4}
                    sx={{ textAlign: { xs: "center" } }}
                  >
                    <Typography
                      sx={{ fontWeight: 600 }}
                      component={"p"}
                      mb={2}
                      variant="h6"
                    >
                      {el.caption}
                    </Typography>
                    {el.subs.map((list) => {
                      return (
                        <ListItem
                          key={list.name}
                          className={classes.listItem}
                          component={"a"}
                          button
                          onClick={(e) => {
                            e.stopPropagation();
                            navigate(list.path);
                          }}
                        >
                          <ListItemText>
                            <Typography
                              textAlign={"center"}
                              component={"p"}
                              variant="body2"
                            >
                              {list.name}
                            </Typography>
                          </ListItemText>
                        </ListItem>
                      );
                    })}
                  </Grid>
                );
              })}
            </Grid>
          </Box>
          <Divider sx={{ margin: "3rem 0" }} />
          <Box>
            <Typography
              variant="body2"
              color={"textSecondary"}
              textAlign="center"
              paragraph
            >
              By searching, you agree to the{" "}
              <Link underline="none" color={"secondary"} href="/terms-of-use">
                Terms of Use
              </Link>{" "}
              and{" "}
              <Link color={"secondary"} underline="none" href="/privacy-policy">
                Privacy Policy
              </Link>
              .<br /> Do Not Sell My Personal Information. <br />
              California DRE# 02190813. Our Commitment to Accessibility
            </Typography>
            <Typography
              variant="body2"
              color={"textSecondary"}
              textAlign="center"
            >
              BPO HOMES is committed to making our website accessible and user
              friendly to all. While we are constantly working to improve, we
              welcome your feedback and improvement requests. If you are having
              difficulty accessing or navigating our website, or if you have any
              suggestions to improve accessibility. If you wish to report an
              issue or seek an accommodation , please call BPO Customer support
              for help at 1-888-616-5270
            </Typography>
          </Box>
          <Divider sx={{ margin: "3rem 0" }} />
          <Hidden smDown>
            <Box display={"flex"} justifyContent="space-evenly">
              {menu.map((item) => {
                return (
                  <Link
                    style={{ cursor: "pointer" }}
                    color={"secondary"}
                    underline="none"
                    key={item.name}
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate(item.path);
                    }}
                  >
                    <Typography variant="body2">{item.name}</Typography>
                  </Link>
                );
              })}
            </Box>
          </Hidden>
          <Box textAlign={"center"} margin="2rem 0">
            <img
              src={Logo}
              alt="logo-foot"
              className={classes.footImg}
              width={"25%"}
              style={{ marginBottom: ".5rem" }}
            />
            <span
              style={{ display: "flex", gap: "2rem", justifyContent: "center" }}
            >
              <Typography variant="body2" color={"textSecondary"}>
                © BPO HOMES Inc
              </Typography>
              {/* <Typography variant='body2' color={'textSecondary'}>Terms of Services</Typography>
						<Hidden smDown>
							<Typography variant='body2' color={'textSecondary'}>Privacy Policy</Typography>
						</Hidden> */}
            </span>
          </Box>
          {(path.startsWith("/listing") || path.startsWith("/open-houses")) &&
            vendor === "mls" && (
              <>
                <Divider sx={{ marginBottom: "1.5rem" }} />
                <img src={MLS} alt="mls-logo" style={{ marginBottom: "5px" }} />
                <Typography sx={{ fontSize: ".8rem" }} color="GrayText">
                  Property Information © 2022 MLSListings Inc. All rights
                  reserved. Listing information is deemed reliable but not
                  guaranteed. Certain information contained herein is derived
                  from information which is the licensed property of, and
                  copyrighted by, MLSListings Inc. The viewer acknowledges
                  ownership and validity of its copyright. Information provided
                  is for personal, non-commercial use by a viewer with a bona
                  fide interest in the purchase or sale of real estate of the
                  type being offered via this website or other electronic means.
                  The viewer is prohibited from copying, redistributing or
                  retransmitting any of the data or information provided by
                  MLSListing, Inc.
                </Typography>
              </>
            )}
          {(path.startsWith("/listing") || path.startsWith("/open-houses")) &&
            vendor === "crmls" && (
              <>
                <Divider sx={{ marginBottom: "1.5rem" }} />
                <Typography sx={{ fontSize: ".8rem" }} color="GrayText">
                  Based on information from California Regional Multiple Listing
                  Service, Inc. as of 2023. This information is for your
                  personal, non-commercial use and may not be used for any
                  purpose other than to identify prospective properties you may
                  be interested in purchasing. Display of MLS data is usually
                  deemed reliable but is NOT guaranteed accurate by the MLS.
                  Buyers are responsible for verifying the accuracy of all
                  information and should investigate the data themselves or
                  retain appropriate professionals. Information from sources
                  other than the Listing Agent may have been included in the MLS
                  data. Unless otherwise specified in writing, Broker/Agent has
                  not and will not verify any information obtained from other
                  sources. The Broker/Agent providing the information contained
                  herein may or may not have been the Listing and/or Selling
                  Agent.
                </Typography>
              </>
            )}
        </Container>
      </Box>
    </>
  );
};

export default Footer;
