import "./App.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { connect } from "react-redux";
import { ThemeProvider } from "@mui/material";
import { theme } from "./theme";
import { Routes, Route } from "react-router-dom";
import { lazy, Suspense, useState } from "react";
import Homepage from "./layout/Homepage"; // does not need to lazy load
import PublicRoute from "./constants/PublicRoute"; // no need to lazy load
import SEO from "./components/SEO"; // no lazy
import { PaymentSuccessIntern } from "./layout/PaymentSuccess";
import GeneralLoader from "./GeneralLoader";
const Buy = lazy(() => import("./layout/Buy"));
const PropertyDetails = lazy(() => import("./layout/PropertyDetails"));
const AboutPartner = lazy(() => import("./layout/PartnerPremier/AboutPartner"));
const BuyerChecklist = lazy(() => import("./layout/Resources/BuyerChecklist"));
const BuyerFirstTime = lazy(() => import("./layout/Resources/BuyerFirstTime"));
const BuyerScore = lazy(() => import("./layout/Resources/BuyerScore"));
const BPOGuide = lazy(() => import("./layout/Resources/BPOGuide"));
const SellerGuide = lazy(() => import("./layout/Resources/SellerGuide"));
const HomeImprovement = lazy(() => import("./layout/Resources/HomeImprovement"));
const Explore = lazy(() => import("./layout/Resources/Explore"));
const JoinBPO = lazy(() => import("./layout/JoinBPO/JoinBPO"));
const BPOSignup = lazy(() => import("./layout/JoinBPO/BPOSignup"));
const Sell = lazy(() => import("./layout/Sell"));
const Rent = lazy(() => import("./layout/Rent"));
const AgentFinder = lazy(() => import("./layout/AgentFinder"));
const SingleAgentFinder = lazy(() => import("./layout/SingleAgentFinder"));
const Modal = lazy(() => import("./components/Modal"));
const Errorpage = lazy(() => import("./layout/ErrorPage"));
const PrivacyPolicy = lazy(() => import("./layout/PrivacyPolicy"));
const TermsOfUse = lazy(() => import("./layout/TermsOfUse"));
const About = lazy(() => import("./layout/About"));
const PaymentSuccess = lazy(() => import("./layout/PaymentSuccess"));
const AuthLoader = lazy(() => import("./components/AuthLoader"));
const ResetPassword = lazy(() => import("./layout/ResetPassword"));
const OpenHouses = lazy(() => import("./layout/OpenHouses"));
const Disclaimer = lazy(() => import("./layout/Disclaimers"));
const RealtyAgentSearch = lazy(() => import("./layout/RealtyAgentSearch"));
const HomeInspection = lazy(() => import("./layout/Resources/HomeInspection"));
const ChoosingTheRightHome = lazy(() => import("./layout/Resources/ChoosingTheRightHome"));
const NewRoofBenefits = lazy(() => import("./layout/Resources/NewRoofBenefits"));
const KitchenRemodel = lazy(() => import("./layout/Resources/KitchenRemodel"));
const MaximizingSpace = lazy(() => import("./layout/Resources/MaximizingSpace"));
const Blog = lazy(() => import("./layout/Blog"));
const CreatingThePerfectDesign = lazy(() => import("./layout/Resources/CreatingThePerfectDesign"));
const JuniorMasterBedroom = lazy(() => import("./layout/Resources/JuniorMasterBedroom"));
const DoublePaneWindows = lazy(() => import("./layout/Resources/DoublePaneWindows"));
const FloorOptions = lazy(() => import("./layout/Resources/FloorOptions"));
const PlumbingRealEstate = lazy(() => import("./layout/Resources/PlumbingRealEstate"));
const PlumbingFixtures = lazy(() => import("./layout/Resources/PlumbingFixtures"));
const Faucets = lazy(() => import("./layout/Resources/Faucet"));
const FaucetBenefits = lazy(() => import("./layout/Resources/FaucetBenefits"));
const Investors = lazy(() => import("./layout/Resources/Investors"));
const SoldHomes = lazy(() => import("./layout/SoldHomes"));
const InvestorList = lazy(() => import("./layout/investorList"));
const BrokerageComp = lazy(() => import("./components/Brokerage/BrokerageComp"));
const AgentsComponent = lazy(() => import("./components/agents/AgentsComponent"));
const InternPricing = lazy(() => import("./components/Brokerage/pricing/InternPricing"));
const App = (props) => {
  const [show, setShow] = useState(true);

  const hideFooter = () => {
    setShow(false);
  };

  const showFooter = () => {
    setShow(true);
  };

  return (
    <Suspense fallback={<GeneralLoader />} >
      <SEO>
        <meta
          name="description"
          data-react-helmet="true"
          content="Don't Underlist. Don't Overpay. Get A BPO Report Today. Your Local Expert Agents Ready To Give You A BPO Report Now!"
        />
        <title>BPO HOMES</title>
      </SEO>
      <ThemeProvider theme={theme}>
        {props.modal && <Modal />}
        {props.loader && <AuthLoader open={props.loader} />}

        <Routes>
          <Route element={<PublicRoute footer={show} />}>
            <Route path="/investors" element={<InvestorList />} />
            <Route path="/" element={<Homepage />} />
            <Route path="/sell" element={<Sell />} />
            <Route
              path="/homes-for-rent"
              element={<Rent hide={hideFooter} show={showFooter} />}
            />
            <Route
              exact
              path="/homes-for-sale"
              element={<Buy hide={hideFooter} show={showFooter} />}
            />
            <Route
              exact
              path="/homes-for-sale/:city"
              element={<Buy hide={hideFooter} show={showFooter} />}
            />
            <Route
              exact
              path="/sold-homes"
              element={<SoldHomes hide={hideFooter} show={showFooter} />}
            />
            <Route
              exact
              path="/open-houses"
              element={<OpenHouses hide={hideFooter} show={showFooter} />}
            />
            <Route exact path="/agents" element={<AgentFinder />} />
            <Route exact path="/brokerage" element={<BrokerageComp />} />
            <Route exact path="/agentComp" element={<AgentsComponent />} />
            <Route exact path="/internPrice" element={<InternPricing />} />
            <Route exact path="/agents/:id" element={<SingleAgentFinder />} />
            <Route
              exact
              path="/listing/:id/details/:vendor"
              element={<PropertyDetails />}
            />
            <Route
              exact
              path="/open-houses/:id/details/:vendor"
              element={<PropertyDetails />}
            />
            <Route
              exact
              path="/sold-homes/:id/details/:vendor"
              element={<PropertyDetails />}
            />
            <Route
              exact
              path="/rental/:id/details/:vendor"
              element={<PropertyDetails />}
            />
            <Route path="/blog">
              <Route path="" element={<Blog />} />
              <Route path="floor-options" element={<FloorOptions />} />
              <Route path="faucets" element={<Faucets />} />
              <Route path="investors" element={<Investors />} />
              <Route path="quality-faucets" element={<FaucetBenefits />} />
              <Route path="plumbing-fixtures" element={<PlumbingFixtures />} />
              <Route
                path="plumbing-real-estate"
                element={<PlumbingRealEstate />}
              />
              <Route
                path="double-pane-windows"
                element={<DoublePaneWindows />}
              />
              <Route
                path="perfect-design"
                element={<CreatingThePerfectDesign />}
              />
              <Route
                path="junior-master-bedrooms"
                element={<JuniorMasterBedroom />}
              />
              <Route path="buyer-checklist" element={<BuyerChecklist />} />
              <Route path="buyer-firsttimer" element={<BuyerFirstTime />} />
              <Route path="buyer-score" element={<BuyerScore />} />
              <Route path="bpo-guide" element={<BPOGuide />} />
              <Route path="sellers-guide" element={<SellerGuide />} />
              <Route path="home-improvement" element={<HomeImprovement />} />
              <Route path="home-inspection" element={<HomeInspection />} />
              <Route
                path="choosing-the-right-home"
                element={<ChoosingTheRightHome />}
              />
              <Route
                path="benefits-of-a-new-roof"
                element={<NewRoofBenefits />}
              />
              <Route path="kitchen-remodeling" element={<KitchenRemodel />} />
              <Route path="maximizing-space" element={<MaximizingSpace />} />
              <Route path="explore" element={<Explore />} />
            </Route>
            <Route path="/join">
              <Route path="" element={<JoinBPO />} />
              <Route path="signup" element={<BPOSignup />} />
            </Route>
            <Route path="/partners">
              <Route path="" element={<RealtyAgentSearch />} />
              <Route path="benefits" element={<AboutPartner />} />
            </Route>
            <Route
              path="/reset_password/:id/:token"
              element={<ResetPassword />}
            />
            <Route path="/realty-agents" element={<RealtyAgentSearch />} />
            <Route
              path="/realty-agents/:search"
              element={<RealtyAgentSearch />}
            />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-of-use" element={<TermsOfUse />} />
            <Route path="/disclaimers" element={<Disclaimer />} />
            <Route path="/about" element={<About />} />
            <Route path="/payment-success" element={<PaymentSuccess />} />
            <Route
              path="/payment-success-intern"
              element={<PaymentSuccessIntern />}
            />

            <Route path="*" element={<Errorpage />} />
          </Route>
        </Routes>
      </ThemeProvider>
    </Suspense>
  );
};

const mapStateToprops = (state) => {
  //console.log(state)
  return state;
};

export default connect(mapStateToprops, {})(App);
