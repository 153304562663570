import Snackbar from "@mui/material/Snackbar";
import Slide from "@mui/material/Slide";
import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import { SavedCardsModal } from "./SavedCardsModal";

function SlideTransition(props) {
  return <Slide {...props} direction="up" />;
}

export const FavoriteBar = () => {
  return <div>Favorite</div>;
};

export const FavoritePopUp = ({
  children,
  open,
  setOpen,
  primaryFavorite,
  categories,
  setCategories,
}) => {
  const [openFavoriteModal, setOpenFavoriteModal] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <SavedCardsModal
        primaryFavorite={primaryFavorite}
        setCategories={setCategories}
        categories={categories}
        openFavoriteModal={openFavoriteModal}
        setOpenFavoriteModal={setOpenFavoriteModal}
      />
      <Snackbar
        open={open}
        style={{ backgroundColor: "white" }}
        onClose={handleClose}
        sx={{ borderRadius: 10, width: "24rem" }}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        TransitionComponent={SlideTransition}
        // key={state.Transition.name}
        autoHideDuration={5000}
      >
        <Box
          sx={{
            width: "100%",
            bgcolor: "white",
            display: "flex",
            justifyContent: "space-between",
            padding: 1.5,
            borderRadius: 3,
            boxShadow: "1px 1px 10px rgba(0,0,0, 0.3)",
          }}
        >
          <Typography>Added to {primaryFavorite?.name}</Typography>
          <Typography
            color={"primary"}
            fontWeight={500}
            sx={{ textDecoration: "underline", cursor: "pointer" }}
            onClick={() => {
              setOpenFavoriteModal(true);
            }}
          >
            {categories.length <= 1 ? "Change List" : "View List"}
          </Typography>
        </Box>
      </Snackbar>
    </div>
  );
};
