import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { legacy_createStore as createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import reducers from "./reducers";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { HelmetProvider } from "react-helmet-async";
import ReactGA from "react-ga4";

ReactGA.initialize("G-21WC2VDTB1");

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={createStore(reducers, applyMiddleware(thunk))}>
    <GoogleOAuthProvider
      clientId={process.env.REACT_APP_SOCIAL_AUTH_GOOGLE_OAUTH2_KEY}
    >
      <BrowserRouter>
        <HelmetProvider>
          <App />
        </HelmetProvider>
      </BrowserRouter>
    </GoogleOAuthProvider>
  </Provider>
);
